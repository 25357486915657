<template>
  <div
    class="activity container van-tab__panel">
     <ul>
       <li v-for="item in activityList.filter(f=>f.status===1)" :key="item.ident" @click="goPagePath(item.ident)" class="default border-r5">
       <img :src="item.list_img" alt="">
       <h3>{{item.name}}</h3>
       <p>{{item.summary}}</p>
       </li>
     </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getActivity } from "@/api/public";
import { useRouter } from 'vue-router'

const router = useRouter()
const activityList=ref([])
getActivity().then(res=>{
activityList.value=res.data;
})
const goPagePath = (ident) => {
  router.push('/pages/activityDetail?name='+ident)
}
</script>

<style lang="less" scoped>
.activity {
  ul li{
    margin-bottom: 10px;
    padding: 10px;
&:nth-child(odd){
   background: #18253e;
  }
  img{
    max-width: 100%;
    max-height: 50px;
  }
  }
}
</style>
